import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import useStyles from "./Automotive.style";
import { RutInput, TextInput } from "@components/Inputs";
import { ButtonPrimary } from "@components/Reusables";
import { MarkSelector } from "@components/Inputs/SingleSelectors/Services/MarkSelector";
import useGeneralStyles from "@components/Services/styles";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { useForm } from "@hooks";
import { TypeCar } from "@components/Inputs/SingleSelectors/Services/TypeCar";

interface AutomotiveProps {
  className?: string;
  setFinishSave: (value: any) => void;
  setStepContinue: () => void;
  onSend: () => void;
}
const Automotive = ({
  className,
  setFinishSave,
  setStepContinue,
  onSend,
}: AutomotiveProps) => {
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const classes = useStyles();
  const classesGeneral = useGeneralStyles();
  const [form] = useForm({});

  const [marca, setMarca] = useState<string>();
  const [modelo, setModelo] = useState<string>();
  const [date, setDate] = useState<string>();
  const [uso, setUso] = useState<string>();
  const [rut, setRut] = useState<string>();

  const enabled = !!(marca && modelo && date && uso && rut);

  return (
    <>
      <HeaderServices
        title="SEGUROS"
        steps={["Automotriz"]}
        onBackClick={() => {
          setStepContinue();
        }}
      />
      <div className={`${className || ""} ${classes.container}`}>
        <div className={classes.title}>
          ¡Siempre es bueno proteger tu auto ante accidentes e imprevistos!
        </div>
        <section className={classes.containerList}>
          <div className="input">
            <span className={classesGeneral.secondaryTitle}>Marca</span>
            <TextInput
              onChange={(e) => {
                setMarca(e.target.value as string);
              }}
              name="a"
              placeholder="Marca de auto"
              value={marca}
            />
          </div>
          <div className="input">
            <span className={classesGeneral.secondaryTitle}>Modelo</span>{" "}
            <TextInput
              onChange={(e) => {
                setModelo(e.target.value as string);
              }}
              name="a"
              placeholder="Modelo de auto"
              value={modelo}
            />
          </div>
          <div className="input">
            <span className={classesGeneral.secondaryTitle}>Tipo de uso</span>
            <TypeCar
              className={`${className || ""} altercap-name-selector`}
              name={"name"}
              onChange={(e) => {
                setUso(e.target.value as string);
              }}
              value={uso}
            />
          </div>
          <div className="input">
            <span className={classesGeneral.secondaryTitle}>Tu RUT</span>
            <RutInput
              onChange={(e) => {
                setRut(e.target.value as string);
              }}
              value={rut}
            />
          </div>
          <div className="input">
            <span className={classesGeneral.secondaryTitle}>Año</span>
            <TextInput
              onChange={(e) => {
                setDate(e.target.value as string);
              }}
              name="a"
              type={"date"}
              placeholder="Año"
              value={date || ""}
            />
          </div>
        </section>{" "}
        <div className={classes.button}>
          <ButtonPrimary
            disabled={!enabled}
            className={classes.buttonPrimary}
            noDegraded
            text="Enviar"
            onClick={() => {
              form.sure = {
                ...form.sure,
                "Marca de auto": marca,
                Modelo: modelo,
                "Tipo de uso": uso,
                Rut: rut,
                Año: date,
              };
              onSend();

              setFinishSave({
                Notification2448: true,
                BallCharge: false,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
export default Automotive;
