import React from "react";
import { CommonSelectorProps, Select } from "@components";

export const TypeCar: React.FC<CommonSelectorProps> = ({
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Select
      className={`${className || ""} altercap-gender-selector`}
      name={name || "reazonContact"}
      label={label || "Tipo de auto"}
      options={[
        {
          name: "Particular",
          value: "Particular",
        },
        {
          name: "Comercial",
          value: "Comercial",
        },
      ]}
      {...props}
    />
  );
};
