import React, { useContext, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { SEO } from '@components';
import { LoadingContext } from '@context';
import { getAuth } from 'firebase/auth';
import LayoutServices from '@components/Services/Layout/LayoutServices';
import YouSeek from '@components/Services/YouSeek/YouSeek';
import Categories from '@components/Services/Categories/Accident';
import AccidentPersonalizedAnalysis from '@components/Services/Categories/Accident/AccidentPersonalized';
import Automotive from '@components/Services/Categories/Automotive/Automotive';
import Sport from '@components/Services/Categories/Sport/Sport';
import { useAuth } from '@hooks';

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;
  //nro de pagina dependiento el seguro
  const [categorySelect, setCategorySelect] = useState(-1);
  const [subcategorySelect, setSubcategorySelect] = useState(0);
  const isMobile = useMediaQuery('(max-width: 1200px)');

  //accidentes, automotriz atc..
  const [titleState, setTitleState] = useState<string>();

  useEffect(() => {
    hideLoader('');
  }, [currentUser]);

  /*
    | "Accidentes"
    | "Automotriz"
    | "Deporte"
    | "Hogar"
    | "Mascotas" p
    | "Salud"
    | "Oncológico"
    | "Viaje"
    | "Vida"
     */
  const onBackClick = () => {
    subcategorySelect === 0 && categorySelect !== -1 && setCategorySelect(-1);
    subcategorySelect > 0 && setSubcategorySelect(subcategorySelect - 1);
  };
  return (
    <>
      <SEO
        title="Tu asistente personal financiero - Rokin"
        description="Recibe apoyo y orientación personalizados para resolver tus problemas y dudas financieras."
      />
      <LayoutServices
        title="SERVICIOS"
        onBackClick={onBackClick}
        state={titleState}
        key="Bottom"
        hiddenHeader={!isMobile}
        activeServices
      >
        {categorySelect === -1 && (
          <YouSeek
            setCategorySelect={setCategorySelect}
            onClick={setTitleState}
          />
        )}
        {categorySelect === 0 &&
          (subcategorySelect === 0 ? (
            <Categories
              setPersonalizedAnalysis={setSubcategorySelect}
              onClick={setTitleState}
            />
          ) : (
            <AccidentPersonalizedAnalysis
              setPersonalizedAnalysis={setSubcategorySelect}
            />
          ))}
        {categorySelect === 1 && subcategorySelect === 0 && (
          <Automotive setSubCategorySelect={setSubcategorySelect} />
        )}
        {categorySelect === 2 && subcategorySelect === 0 && (
          <Sport setSubCategorySelect={setSubcategorySelect} />
        )}
      </LayoutServices>
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({}));
